<template>
  <div class="loginRegBox">
    <div class="loginReg">
      <div class="logo">
        <img @click="gotoHome" src="../../assets/img/logo.png" alt="" />
      </div>
      <div class="menus">
        <router-link
          :to="item.link"
          tag="span"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <span v-if="item.show">
            {{ item.name }}
          </span>
        </router-link>
      </div>
      <div>
        <el-button
          v-if="!haveToken"
          type="primary"
          size="mini"
          @click="dialogVisible = true"
          >Log in</el-button
        >
        <el-button v-else type="primary" size="mini" @click="logOutFun"
          >Log out</el-button
        >
      </div>
    </div>
    <el-dialog
      title="Login"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <div class="from-item">
            <el-input
              v-model="form.email"
              placeholder="please input your email"
            ></el-input>
            <el-button :disabled="!show">
              <span v-show="show" @click="getCodeFun">Get Code</span>
              <span v-show="!show" class="count">{{ count }} s</span>
            </el-button>
          </div>
          <div class="form-item">
            <el-input
              v-model="form.code"
              placeholder="please enter verification code"
            ></el-input>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="loginComfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCode, logIn, logOut } from '@/api/interface.js'
export default {
  name: 'loginReg',
  data() {
    return {
      form: {
        email: '',
        code: '',
      },
      dialogVisible: false,

      show: true,
      count: '',
      timer: null,
    }
  },
  computed: {
    haveToken() {
      return this.$store.state.accessToken
    },
    menuList() {
      return [
        // {
        //   name: 'ABOUT US',
        //   link: '/',
        //   show: true,
        // },
        // {
        //   name: 'WORK WITH US',
        //   link: '/',
        //   show: true,
        // },
        // {
        //   name: 'BLOG',
        //   link: '/',
        //   show: true,
        // },
        {
          name: 'My Dashboard',
          link: '/info',
          show: this.haveToken,
        },
      ]
    },
  },
  mounted() {},
  methods: {
    gotoHome() {
      this.$router.push('/')
    },
    countDown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    getCodeFun() {
      const params = {
        email: this.form.email,
        scene: 29,
      }
      getCode(params).then((res) => {
        console.log(res)
        this.countDown()
      })
    },
    loginComfirm() {
      const params = {
        authCode: this.form.code,
        email: this.form.email,
        scene: 29,
      }
      logIn(params)
        .then((res) => {
          if (res.code === '0') {
            if (res.data) {
              this.$store.commit('setToken', res.data.token)
              this.dialogVisible = false
              this.$router.push('/info')
            }
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.dialogVisible = true
        })
    },
    logOutFun() {
      const params = {}
      logOut(params).then(() => {
        this.$store.commit('setToken', '')
        this.$router.push('/')
        this.$message.success('Log Out Success')
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.loginRegBox {
  width: 100%;
  height: 100px;
  background-color: #14171c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .loginReg {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo > img {
      width: 140px;
      padding: 30px 0;
    }
    .menus {
      width: 700px;
      font-weight: 600;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  .el-form {
    .from-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .el-button {
        margin-left: 10px;
      }
    }
  }
}
</style>
