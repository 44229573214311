import request from './http' // 使用实例
export const getCode = (params) => {
  return request({
    url: '/v2/user/auth/email/code',
    method: 'post',
    data: params,
  })
}
export const logIn = (params) => {
  return request({
    url: '/v2/user/auth/email/login',
    method: 'post',
    data: params,
  })
}

export const logOut = (params) => {
  return request({
    url: '/v2/user/auth/login/out',
    method: 'post',
    data: params,
  })
}

export const baseInfo = (params) => {
  return request({
    url: '/v2/quote/mm/get_basic_info',
    method: 'get',
    params,
  })
}

export const historyTrading = (params) => {
  return request({
    url: '/v2/quote/mm/get_history_trading_status',
    method: 'get',
    params,
  })
}

export const getSymbolList = (params) => {
  return request({
    url: '/v2/quote/mm/get_symbol_list',
    method: 'get',
    params,
  })
}
